// don't change this content

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

* {
  font-family: 'GEA Inter Regular', serif;
}

.gea-hrt-app .mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit;
}

.gea-hrt-app .gea-select-field.p-float-label > input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: -14px !important;
  font-size: 13px !important;
  left: -7px;
}

.gea-hrt-app .gea-multiselect-field.p-float-label > input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: -14px !important;
  font-size: 13px !important;
  font-weight: 400;
  left: -7px;
}

.gea-hrt-app .gea-multiselect-field.p-float-label > .gea-multiselect-font-weight-500 {
  font-weight: 400;
}

.gea-multiselect-field.p-float-label > label {
  top: 33% !important;
}

.gea-autocomplete-select-container span label {
  top: 33% !important;
}

gea-hrt-health-check-dialog {
  height: 80vh;
  display: block;
}
